*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.image-area-container .ant-image {
  float: left;
}
.image-area-container .ant-image .ant-image-img {
  float: left;
}
.image-area-set {
  width: 375px;
  margin: 0 auto;
}
.image-area-set .input-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.image-area-set .img {
  position: relative;
}
.image-area-set .img .change-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 10px;
  z-index: 2;
  left: 10px;
  cursor: pointer;
}
.image-area-set .img .preview {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 2;
}
.image-area-set .img .hot-area-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-area-set .img .hot-area-list .item {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.image-area-set .img .hot-area-list .item.selected {
  background-color: rgba(255, 117, 11, 0.6);
  border: 2px solid #1890ff;
}
.image-area-set .img .hot-area-list .item .delete {
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.image-area-set .custom-form .input-item {
  display: flex;
  align-items: center;
}
.image-area-set .dragger-con {
  padding: 20px 10px;
  margin-bottom: 10px;
  border: 1px dashed #eee;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-area-set .dragger-con .add-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-area-set .dragger-con .icon {
  font-size: 30px;
  color: #333;
}
.image-area-set .dragger-con .desc {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
