/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.right-content .menu :global(.anticon) {
  margin-right: 8px;
}
.right-content .menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.right-content .user-info {
  display: flex;
  align-items: center;
}
.right-content .user-info .name {
  margin-left: 5px;
  cursor: pointer;
  font-size: 13px;
}
.right-content .container.right {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
}
.right-content .container.right .action {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.right-content .container.right .action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right-content .container.right .action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}
.right-content .container.right .search {
  padding: 0 12px;
}
.right-content .container.right .search:hover {
  background: transparent;
}
.right-content .container.right .account .avatar {
  margin-right: 8px;
  color: #1890ff;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.85);
}
.right-content .dark .action:hover {
  background: #252a3d;
}
.right-content .dark .action:global(.opened) {
  background: #252a3d;
}
@media only screen and (max-width: 768px) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
  }
  .right .account .avatar {
    margin-right: 0;
  }
  .right .search {
    display: none;
  }
}
