*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.goods-container .item {
  background-color: #fff;
  width: 174px;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
}
.goods-container .item.scroll-item {
  width: 100px;
  margin-right: 8px;
}
.goods-container .item.scroll-item .img {
  width: 100px;
  height: 100px;
}
.goods-container .item.scroll-item .info {
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goods-container .item.scroll-item .info .name {
  display: none;
}
.goods-container .item.scroll-item .info .price {
  color: #fff;
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: #EA1F36;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.goods-container .item.scroll-item .info .price .l {
  line-height: 14px;
  font-size: 12px;
  margin-top: 2px;
}
.goods-container .item.scroll-item .info .price .c {
  font-size: 14px;
  line-height: 16px;
  margin-left: 1px;
}
.goods-container .item.scroll-item .info .price .r {
  line-height: 14px;
  font-size: 12px;
  margin-top: 2px;
}
.goods-container .item.scroll-item .info .origin {
  font-size: 12px;
  color: #d0d0d6;
  line-height: 17px;
  text-decoration: line-through;
}
.goods-container .item.big-img-item {
  width: 100%;
}
.goods-container .item.big-img-item .img {
  width: 100%;
  height: 150px;
  border-radius: 8px 8px 0 0;
}
.goods-container .item.big-img-item .info {
  position: relative;
  padding-bottom: 20px;
}
.goods-container .item.big-img-item .info .buy-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.goods-container .item.list-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.goods-container .item.list-item .img {
  width: 140px;
  height: 140px;
  border-radius: 8px;
}
.goods-container .item.list-item .info {
  width: 220px;
  height: 140px;
  position: relative;
}
.goods-container .item.list-item .info .name {
  font-size: 14px;
  line-height: 22px;
}
.goods-container .item.list-item .info .price {
  position: absolute;
  bottom: 15px;
  left: 20px;
}
.goods-container .item.collageList-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.goods-container .item.collageList-item .img {
  width: 140px;
  height: 140px;
  border-radius: 8px;
}
.goods-container .item.collageList-item .dumpling-box {
  padding: 0 4px;
  font-size: 12px;
  border: 1px solid #FE5366;
  border-radius: 4px;
  display: inline-block;
  color: #FE5366;
}
.goods-container .item.collageList-item .info {
  width: 220px;
  height: 140px;
  position: relative;
}
.goods-container .item.collageList-item .info .name {
  font-size: 14px;
  line-height: 22px;
}
.goods-container .item.collageList-item .info .price {
  position: absolute;
  width: 202px;
  bottom: 0px;
  left: 8px;
  height: 36px;
  line-height: 36px;
  display: flex;
  background-image: url('../../../assets/images/img_pintuan_price_bg@2x.png');
  background-size: 100% 100%;
}
.goods-container .item.collageList-item .info .price .original {
  text-decoration: line-through;
  color: #FFFFFF;
  font-weight: 100;
  margin-left: 8px;
  line-height: 30px;
}
.goods-container .item.collageList-item .info .price .retailPrice {
  color: white;
  display: flex;
  height: 36px;
  margin-left: 10px;
}
.goods-container .item.collageList-item .info .price .retailPrice span {
  font-size: 12px;
  line-height: 40px;
  margin-top: 10px;
  margin-top: 3px;
}
.goods-container .item.collageList-item .info .price .retailPrice .num {
  margin-top: 0;
  font-size: 20px;
}
.goods-container .item.collageList-item .info .price .text {
  color: #5F3504;
  margin-left: auto;
  width: 50px;
}
.goods-container .item .img {
  width: 174px;
  height: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}
.goods-container .item .info {
  padding: 8px;
}
.goods-container .item .info .name {
  font-size: 14px;
  color: #101012;
  line-height: 20px;
  margin-bottom: 4px;
}
.goods-container .item .info .price {
  color: #EA1F36;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
}
.goods-container .item .info .price .l,
.goods-container .item .info .price .r {
  line-height: 19px;
}
.goods-container .item .info .price .c {
  font-size: 18px;
  line-height: 21px;
  margin-left: 2px;
}
.goods-container .goods-list {
  padding: 0 9px;
  padding-top: 8px;
}
.goods-container .goods-list .left {
  float: left;
}
.goods-container .goods-list .right {
  float: right;
}
.goods-container .scroll-con {
  overflow-x: scroll;
  width: 355px;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 4px;
}
.goods-container .scroll-con.is-group {
  border-radius: 0 0 12px 12px;
}
.goods-container .scroll-con .scroll-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 375px;
  padding: 0 8px;
}
