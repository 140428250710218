*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.popup-container .popup-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.popup-container .title {
  text-align: center;
  line-height: 40px;
  background-color: #fff;
  width: 355px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 4px;
}
.popup-set {
  padding: 0 20px;
}
.popup-set .img {
  position: relative;
}
.popup-set .img .change-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 10px;
  z-index: 2;
  left: 10px;
  cursor: pointer;
}
.popup-set .img .preview {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 2;
}
.popup-set .custom-form .input-item {
  display: flex;
  align-items: center;
}
.popup-set .dragger-con {
  padding: 20px 10px;
  margin-bottom: 10px;
  border: 1px dashed #eee;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-set .dragger-con .add-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-set .dragger-con .icon {
  font-size: 30px;
  color: #333;
}
.popup-set .dragger-con .desc {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
