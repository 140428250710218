*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.auxiliary-blank-container {
  width: 355px;
  margin: 0 auto;
}
.auxiliary-blank-container .blank- {
  height: 20px;
}
.auxiliary-blank-setting .set-color-hover {
  width: 32px;
  height: 32px;
  background-color: #000000;
  border: 2px solid #ccc;
  border-radius: 2px;
}
