*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.setting-container {
  padding-bottom: 20px;
}
.setting-container .goods-tabs .ant-tabs-nav-wrap {
  padding-left: 0px;
}
.setting-container .set-con {
  padding-left: 20px;
}
.setting-container .goods-set-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.setting-container .goods-set-title .left {
  color: #666;
  font-size: 14px;
}
.setting-container .goods-set-list {
  padding: 0 10px;
}
.setting-container .goods-set-list .goods-set-item {
  overflow: hidden;
  display: flex;
  position: relative;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.setting-container .goods-set-list .goods-set-item:nth-last-child(1) {
  border: none;
}
.setting-container .goods-set-list .goods-set-item .img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
}
.setting-container .goods-set-list .goods-set-item .img .number {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 2;
  min-width: 25px;
  height: 25px;
  padding: 0 4px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.6);
  margin-bottom: 11px;
}
.setting-container .goods-set-list .goods-set-item .info {
  width: 200px;
  padding-left: 10px;
  position: relative;
}
.setting-container .goods-set-list .goods-set-item .info .name {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 6px;
}
.setting-container .goods-set-list .goods-set-item .info .price {
  font-size: 13px;
  font-weight: bold;
  color: #EA1F36;
  position: absolute;
  bottom: 15px;
  left: 10px;
  margin-bottom: 0;
}
.setting-container .goods-set-list .goods-set-item .operation {
  position: absolute;
  top: 0px;
  z-index: 2;
  right: 0px;
  padding-top: 10px;
}
.setting-container .goods-set-list .goods-set-item .operation .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 11px;
}
.setting-container .dragger-con {
  padding: 0 10px;
  margin-bottom: 10px;
}
.setting-container .add-area .icon {
  font-size: 30px;
  color: #333;
}
.setting-container .add-area .desc {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
