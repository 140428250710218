.ant-pro-table-list-toolbar {
  position: relative;
}

.TableSearchSelect {
  .ant-form-item-control {
    width: 150px !important;
    max-width: 150px !important;
  }
}

.product-tabs {
  position: absolute;
  left: 0;
  top: 14px;
}

.pim-form-item-hidden {
  height: 0 !important;
  margin: 0 !important;
}

.pim-detail {
  position: relative;
}

.pim-detail .ant-space-item:nth-child(2) {
  position: absolute;
  left: 60px;
}

.status-filter {
  position: absolute;
  left: 0;
  top: 16px;
  width: 800px;
  .ant-space-item{
    width: 800px;
    .ant-tabs{
      width: 800px;
    }
  }
}

.product-list-picture {
  .ant-image {
    width: 80px !important;
  }

  ul {
    padding-left: 10px;

    li {
      list-style: none;
      margin-left: 0;
      color: #333;
      padding-left: 0;
      margin-bottom: 4px;

      .product-name {
        color: #1890ff !important;
        cursor: pointer;
      }

      .link {
        color: #1890ff;
      }
    }

    .title {
      color: #000;
      margin-bottom: 10px;
    }
  }
}

.product-list-picture-pim {
  width: auto !important;
}

.product-details-picture {
  padding-left: 20px;
  width: 220px;

  li {
    list-style: none;
  }

  .product-id-ul {
    li {
      line-height: 36px;
    }
  }
}

.product-list {
  margin-bottom: 10px;

  strong {
    color: #595959;
  }
}

.details-columns-one {
  width: 120px;
}

.spec-box {
  .ant-pro-form-group-container {
    flex-direction: column;
  }
}

.pim-detail-show {
  margin-left: 120px;

  .ant-upload {
    display: none;
  }

  .ant-space-item {
    width: 100%;
  }

  .ant-upload-list-item {
    width: 100%;
    height: auto;
    padding: 0;
    border: none;
  }

  .ant-upload-span {
    a:nth-child(2) {
      display: none;
    }

    span:nth-child(2) {
      display: none;
    }

    span:nth-child(3) {
      display: none;
    }

    .ant-upload-list-item-thumbnail {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.carousel-box {
  width: 120px;

  // display: flex !important;
  // justify-content: center;
  img {
    width: auto;
    width: 100%;
  }
}

.product-details {
  .sku-title {
    margin-top: 20px;
  }
}

.details-detail-img-box {
  width: 300px;
  height: 600px;
  position: relative;
  background: white;
  padding: 60px 19px 19px 20px;

  .mobile-img {
    width: 300px;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .ant-upload-list {
    overflow-x: auto;
    height: 522px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    .ant-upload-list-item {
      margin-top: 0;
    }
  }

  .ant-upload-list::-webkit-scrollbar {
    width: 0;
  }

  .details-detail-img {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    background: white;
    margin: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-sizing: border-box;
    overflow-x: auto;

    li {
      list-style: none;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .details-detail-img::-webkit-scrollbar {
    width: 0;
  }
}

.region-details-regions {
  pointer-events: none !important;

  .ant-select-selector {
    border: none !important;

    .ant-select-selection-overflow-item {
      padding: 6px 12px 6px 0;
    }

    .ant-select-selection-item-remove {
      display: none;
    }
  }
}

.classification-show-img {
  width: 80px;

  .ant-image {
    width: 100% !important;
  }
}

.online-classification-show-img {
  width: 80px;

  .ant-image {
    width: 100% !important;
  }
}

.product-carousel {
  .slick-dots li button {
    background: rgba(0, 0, 0, 0.3) !important;
    opacity: 1 !important;
    box-shadow: 0 0 4px 0 rgba(255, 255, 255) !important;
  }

  .slick-dots .slick-active {
    button {
      background: #1890ff !important;
      height: 100%;
    }
  }
}

.pim-time-column {
  div {
    width: 100%;
    display: flex;
    margin: 5px 0;

    p:nth-child(1) {
      white-space: nowrap;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-weight: bold;
    }
  }
}

.spece-left {
  min-width: 60px;
}

.spece-right {
  flex: 1;
}

.spec-box {
  .ant-space-item {
    .spec-val-box {
      display: flex;
      align-items: baseline;
    }

    .spec-val-btn {
      min-width: 80px;
      display: flex;
      justify-content: space-between;

      span:nth-child(1) {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span:nth-child(2) {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}

.product-id-ul {
  li {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
    padding-right: 10px;
    font-weight: bold;
    color: #666;

    span {
      font-weight: normal;
      color: #000;
    }
  }
}

.product-id-ul-Tooltip {
  margin-bottom: 0px;

  li {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.details-columns-box {
  display: flex;
  justify-content: flex-start;
}

.product-skuItem {
  .disable {
    color: #999;
    cursor: not-allowed;
    background: #f5f5f5;

    span {
      color: #999;
      cursor: not-allowed;
    }
  }
}

.region-edit-box {
  .ant-pro-form-list-container {
    width: 90%;
  }

  // .region-select{
  //   width: 260px;
  // }
}

.editable-input-box {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.operation-btn {
  margin-right: 10px;
}

.delivery-template {
  display: flex;
  margin-bottom: 10px;

  h4 {
    color: #555;
  }
}

.birthdayMonthPurchase {
  padding-left: 20px;
}

.tags-box {
  width: 100%;
}

.delBtnCenter {
  display: flex;
  align-items: center;
}

.width100 {
  width: 100%;
}

.bitian::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.specP {
  span {
    font-weight: bold;
    color: #000;
  }
}

.specPB {
  margin-bottom: 20px;
}
.selectPimTable{
  .ant-pro-form-query-filter{
    .ant-form-item{
      margin-bottom: 0 !important;
    }
  }
  .ant-pro-table-search{
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
}
