*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.goods-cube-container {
  width: 355px;
  margin: 0 auto;
  margin-top: 8px;
}
.goods-cube-container .column .img,
.goods-cube-container .horizontal .img,
.goods-cube-container .vertical .img {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.goods-cube-container .column {
  justify-content: space-between;
  display: flex;
}
.goods-cube-container .horizontal {
  display: flex;
  justify-content: space-between;
}
.goods-cube-container .horizontal.horizontal-2-2 .right .img:nth-last-child(1) {
  margin-top: 8px;
}
.goods-cube-container .horizontal .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.goods-cube-container .horizontal .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1px;
}
.goods-cube-container .vertical .top {
  margin-bottom: 8px;
}
.goods-cube-container .vertical .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goods-cube-set {
  padding-bottom: 20px;
}
.goods-cube-set .set-form {
  padding: 0 10px;
}
.goods-cube-set .image-list {
  padding: 0 10px;
}
.goods-cube-set .image-list .image-item {
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.goods-cube-set .image-list .image-item .img {
  height: 135px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
}
.goods-cube-set .image-list .image-item .img .change-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
  position: absolute;
  top: 10px;
  z-index: 2;
  left: 10px;
  cursor: pointer;
}
.goods-cube-set .image-list .image-item .img .operation {
  position: absolute;
  top: 0px;
  z-index: 2;
  right: 10px;
  padding-top: 10px;
}
.goods-cube-set .image-list .image-item .img .operation .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
  cursor: pointer;
}
.goods-cube-set .image-list .image-item .img .img-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.goods-cube-set .image-list .image-item .info {
  padding: 10px;
}
.goods-cube-set .image-list .image-item .info .input-group .input-item {
  display: flex;
  align-items: center;
}
.goods-cube-set .dragger-con {
  padding: 0 10px;
  margin-bottom: 10px;
}
.goods-cube-set .add-area .icon {
  font-size: 30px;
  color: #333;
}
.goods-cube-set .add-area .desc {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
.goods-cube-set .style-list-con {
  margin-top: -20px;
  margin-bottom: 10px;
}
.goods-cube-set .style-list-con .list-con {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.goods-cube-set .style-list-con .list-con .list-item {
  cursor: pointer;
  margin-right: 15px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container {
  border: 2px solid #eee;
  background-color: #f0f0f0;
  margin-bottom: 4px;
  border-radius: 4px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container.selected {
  border-color: #1890ff;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .column,
.goods-cube-set .style-list-con .list-con .list-item .item-container .horizontal,
.goods-cube-set .style-list-con .list-con .list-item .item-container .vertical {
  padding: 4px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .column .block,
.goods-cube-set .style-list-con .list-con .list-item .item-container .horizontal .block,
.goods-cube-set .style-list-con .list-con .list-item .item-container .vertical .block {
  background-color: #ccc;
  border-radius: 4px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .column {
  display: flex;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .column .block {
  margin-right: 4px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .column .block:nth-last-child(1) {
  margin-right: 0;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .horizontal {
  display: flex;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .horizontal.horizontal-2-2 .right .block:nth-last-child(1) {
  margin-top: 1px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .horizontal .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .horizontal .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .vertical .top {
  margin-bottom: 2px;
}
.goods-cube-set .style-list-con .list-con .list-item .item-container .vertical .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
