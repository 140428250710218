*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.image-ad-container {
  width: 375px;
  margin: 0 auto;
}
.image-ad-container .image-ad-list {
  display: flex;
  line-height: 0;
}
.image-ad-container .image-ad-list.column-1 .image-ad-item {
  width: 100%;
}
.image-ad-container .image-ad-list.column-1 .image-ad-item:nth-child(1) {
  margin-right: 0!important;
}
.image-ad-container .image-ad-list.column-2 .image-ad-item {
  width: 50%;
}
.image-ad-container .image-ad-list.column-2 .image-ad-item:nth-child(2) {
  margin-right: 0!important;
}
.image-ad-container .image-ad-list.column-3 .image-ad-item {
  width: 33.3333%;
}
.image-ad-container .image-ad-list.column-3 .image-ad-item:nth-child(3) {
  margin-right: 0!important;
}
.image-ad-container .image-ad-list.column-4 .image-ad-item {
  width: 25%;
}
.image-ad-container .image-ad-list.column-4 .image-ad-item:nth-child(4) {
  margin-right: 0!important;
}
.image-ad-container .image-ad-list.column-5 .image-ad-item {
  width: 20%;
}
.image-ad-container .image-ad-list.column-5 .image-ad-item:nth-child(5) {
  margin-right: 0!important;
}
.image-ad-container .image-ad-list.column-6 .image-ad-item {
  width: 16.6666%;
}
.image-ad-container .image-ad-list.column-6 .image-ad-item:nth-child(6) {
  margin-right: 0!important;
}
.image-ad-container .image-ad-list .image-ad-item .ant-imag,
.image-ad-container .image-ad-list .image-ad-item .i {
  float: left;
}
.image-ad-set {
  padding-bottom: 20px;
}
.image-ad-set .set-form {
  padding: 0 10px;
}
.image-ad-set .image-list {
  padding: 0 10px;
}
.image-ad-set .image-list .image-item {
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.image-ad-set .image-list .image-item .img {
  height: 135px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
}
.image-ad-set .image-list .image-item .img .change-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
  position: absolute;
  top: 10px;
  z-index: 2;
  left: 10px;
  cursor: pointer;
}
.image-ad-set .image-list .image-item .img .operation {
  position: absolute;
  top: 0px;
  z-index: 2;
  right: 10px;
  padding-top: 10px;
}
.image-ad-set .image-list .image-item .img .operation .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
  cursor: pointer;
}
.image-ad-set .image-list .image-item .img .img-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-ad-set .image-list .image-item .info {
  padding: 10px;
}
.image-ad-set .image-list .image-item .info .input-group .input-item {
  display: flex;
  align-items: center;
}
.image-ad-set .dragger-con {
  padding: 0 10px;
  margin-bottom: 10px;
}
.image-ad-set .add-area .icon {
  font-size: 30px;
  color: #333;
}
.image-ad-set .add-area .desc {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
