*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.main-meeting-container {
  width: 100%;
  position: relative;
}
.main-meeting-container.light .list-nav-tabs .ant-tabs-nav .ant-tabs-ink-bar {
  background-color: #fff;
}
.main-meeting-container.light .list-nav-tabs .ant-tabs-nav .ant-tabs-tab-btn {
  color: #fff;
}
.main-meeting-container.light .list-nav-tabs .anticon-ellipsis {
  color: #fff !important;
}
.main-meeting-container.light .no-more {
  color: #fff !important;
}
.main-meeting-container .list-nav-tabs .ant-tabs-nav {
  margin-bottom: 0;
  width: 100%;
  z-index: 2;
  background-color: transparent;
}
.main-meeting-container .list-nav-tabs .ant-tabs-nav::before {
  border: none;
}
.main-meeting-container .list-nav-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  padding-left: 14px;
}
.main-meeting-container .list-nav-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
  margin-left: 14px;
  padding: 5px 0;
}
.main-meeting-container .list-nav-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab:nth-child(1) {
  margin-left: 0;
}
.main-meeting-container .list-nav-tabs .goods-list-container {
  width: 100%;
  height: 100%;
}
.main-meeting-container .list-nav-tabs .goods-list-container .no-more {
  text-align: center;
  font-size: 12px;
  color: #888;
  line-height: 30px;
}
.main-meeting-container .list-nav-tabs .goods-list-container .loading-con {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.main-meeting-set {
  padding: 0 20px;
  padding-bottom: 20px;
}
.drawer-container .goods-list-con .goods-item {
  overflow: hidden;
  display: flex;
  position: relative;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.drawer-container .goods-list-con .goods-item:nth-last-child(1) {
  border: none;
}
.drawer-container .goods-list-con .goods-item .img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
}
.drawer-container .goods-list-con .goods-item .img .number {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 2;
  min-width: 25px;
  height: 25px;
  padding: 0 4px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.6);
  margin-bottom: 11px;
}
.drawer-container .goods-list-con .goods-item .info {
  width: 200px;
  padding-left: 10px;
  position: relative;
}
.drawer-container .goods-list-con .goods-item .info .name {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 6px;
}
.drawer-container .goods-list-con .goods-item .info .price {
  font-size: 13px;
  font-weight: bold;
  color: #EA1F36;
  position: absolute;
  bottom: 15px;
  left: 10px;
  margin-bottom: 0;
}
.drawer-container .goods-list-con .goods-item .operation {
  position: absolute;
  top: 0px;
  z-index: 2;
  right: 0px;
  padding-top: 10px;
}
.drawer-container .goods-list-con .goods-item .operation .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 11px;
}
