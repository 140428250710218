/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pageName {
  color: #333;
  font-weight: bold;
}
.ant-pro-page-container-children-content {
  margin: 0px;
}
.ListTime {
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: -52px;
  margin-right: 20px;
  z-index: 1;
  position: relative;
  padding-left: 31px;
  font-size: 16px;
  width: 500px;
}
.ant-page-header-heading .ant-form-item-control-input-content {
  margin-right: 16px;
}
.content {
  background: #f7f8fa;
  overflow: hidden;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
}
.content .content_search .ant-form-item {
  margin-bottom: 16px;
}
.content .content_top {
  position: absolute;
  margin-left: -20px;
  top: 12px;
  left: 38px;
  background: #fff;
  z-index: 10;
  padding: 15px 16px 10px;
  width: 98%;
}
.content .content_top .box {
  display: flex;
  width: 100%;
}
.content .content_top .box .ant-tabs {
  width: 100%;
}
.content .content_top .box .ant-tabs .ant-tabs-nav {
  margin-bottom: 1vh;
}
.content .content_top .deleteAll {
  margin-left: 30px;
}
.content .content_top .ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}
.content .content_top .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  margin-left: 0px !important;
  margin-right: 12px;
}
.content .marginTop0 {
  margin-top: 0px !important;
}
.content .content_center {
  margin-top: 69px;
}
.content .content_center .ant-pro-card {
  background: #f7f8fa;
}
.content .content_center .ant-pro-card-body {
  margin-top: 16px;
  background: #fff;
  padding: 20px !important;
  box-sizing: border-box;
}
.content .content_center .ant-pro-card-body .ant-pro-table-list-toolbar {
  background: #fff;
}
.content .content_center .ant-page-header {
  padding: 16px;
}
.content .content_center .ant-page-header .ant-page-header-heading-left {
  display: none;
}
.content .content_center .ant-page-header .ant-page-header-heading {
  justify-content: left !important;
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px 8px !important;
  font-size: 12px !important;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  background: #1890ff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active .anticon-close {
  color: #fff !important;
}
.page_box {
  width: 100%;
  background: #fff;
  box-shadow: 0 -3px 4px rgba(0, 21, 41, 0.08);
  padding: 14px 20px;
  box-sizing: border-box;
}
.page_box .ant-pagination {
  display: flex;
}
.page_box .ant-pagination .ant-pagination-total-text {
  margin-right: auto;
}
.goTop {
  margin-left: auto;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.3);
}
.goTop:hover {
  background: #1890ff;
}
