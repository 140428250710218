*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.tab-nav-container {
  width: 100%;
  overflow-x: scroll;
}
.tab-nav-container .image-ad-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 6px 16px;
}
.tab-nav-container .image-ad-list .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
}
.tab-nav-container .image-ad-list .nav-item:nth-last-child(1) {
  margin-right: 0;
}
.tab-nav-container .image-ad-list .nav-item .img {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-nav-container .image-ad-list .nav-item .txt {
  font-size: 12px;
  color: #797A80;
  line-height: 17px;
  max-width: 50px;
  margin-top: 2px;
}
.tab-nav-set {
  padding-bottom: 20px;
}
.tab-nav-set .set-form {
  padding: 0 10px;
}
.tab-nav-set .image-list {
  padding: 0 10px;
}
.tab-nav-set .image-list .image-item {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}
.tab-nav-set .image-list .image-item .top-con {
  display: flex;
  align-items: flex-start;
}
.tab-nav-set .image-list .image-item .top-con .img {
  height: 80px;
  overflow: hidden;
  position: relative;
  display: flex;
  background-color: #f2f2f2;
}
.tab-nav-set .image-list .image-item .top-con .img .change-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
  position: absolute;
  top: 5px;
  z-index: 2;
  left: 5px;
  cursor: pointer;
}
.tab-nav-set .image-list .image-item .top-con .img .img-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tab-nav-set .image-list .image-item .top-con .info {
  padding: 0 10px;
}
.tab-nav-set .image-list .image-item .top-con .info .input-group .input-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.tab-nav-set .image-list .image-item .operation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
.tab-nav-set .image-list .image-item .operation .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #444;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}
.tab-nav-set .dragger-con {
  padding: 0 10px;
  margin-bottom: 10px;
}
.tab-nav-set .add-area .icon {
  font-size: 30px;
  color: #333;
}
.tab-nav-set .add-area .desc {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
