*,
p,
div {
  padding: 0;
  margin: 0;
}
.clearfix::after,
.clearfix::before {
  content: "";
  display: table;
  clear: both;
}
.text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.login-container {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
}
.login-container .login-main {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.login-container .login-main .logo-con {
  width: 360px;
  height: 550px;
  background-color: #1890ff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  position: relative;
  overflow: hidden;
}
.login-container .login-main .logo-con img {
  width: 360px;
  height: 550px;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
}
.login-container .login-main .logo-con .time {
  font-size: 16px;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  z-index: 1;
}
.login-container .login-main .form-con {
  width: 680px;
  box-sizing: border-box;
  padding: 0px 90px;
}
.login-container .login-main .form-con .form-con-title {
  color: #141414;
  font-size: 21px;
  margin-bottom: 23px;
  text-align: center;
}
.login-container .login-main .form-con .form-con-logo {
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}
.login-container .login-main .form-con .form-con-logo img {
  width: 39px;
}
.login-container .login-main .form-con .captcha-con {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.login-container .login-main .form-con .captcha-con img {
  width: 120px;
  margin-left: 20px;
  margin-top: -28px;
}
.login-container .login-main .form-con .login-code-img {
  width: 60%;
}
.login-phone-code {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.login-phone-code .ant-btn {
  margin-left: 10px;
  margin-top: 6px;
}
.loginBackgroud {
  width: 100%;
  height: 100%;
  background: url(../../assets/login.png) no-repeat 100% 100%;
  text-align: center;
  position: fixed;
}
.login {
  width: 500px;
  padding: 30px;
  background-color: rgba(140, 156, 140, 0.2);
}
.loginImg {
  height: 44px;
  margin-right: 16px;
}
.loginTitle {
  text-align: center;
  margin-bottom: 50px;
}
